// @flow
import { useMemo } from "react";
import { css } from "styled-components";
import { media, mediumHeading, largeSectionHeading } from "@nested/brand";
import { addQueryParams } from "@nested/utils";

const sliceBackground = css`
  padding: 40px 20px;
  background-color: ${({ theme }) => theme.palette.yellow20};
  ${media.tablet`
    padding: 80px 20px 40px;
  `}
  ${media.desktop`
    padding: 80px 20px 40px;
    margin: 0 auto;
  `}
`;

const sliceContent = css`
  max-width: 1140px;
  margin: 0 auto;
`;

const teamWrapper = css`
  display: flex;
  flex-wrap: wrap;
  ${media.tablet`
     margin-top: 40px;
  `}
`;

const titleStyles = css`
  ${mediumHeading}
  margin: 0 0 30px;
  ${media.tablet`
    font-size: 40px;
    margin: 0 0 54px;
  `}
  ${media.desktop`
    margin: 0 0 70px;
  `}
`;

const teamHeadingStyles = css`
  ${largeSectionHeading}
  margin: 0 0 30px;
  width: 100%;
  span {
    color: ${({ theme }) => theme.palette.yellow500};
  }
  ${media.tablet`
    max-width: 228px;
    margin: 0;
    font-size: 32px;
    line-height: 33px;
  `}
`;

const teamMemberWrapper = css`
  width: 50%;
  max-width: 228px;
  margin-bottom: 30px;
  ${media.tablet`
    margin-bottom: 40px;
  `}
`;

const profilePic = css`
  width: calc(100% - 20px);
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
`;

const roleStyles = css`
  font-size: 16px;
  line-height: 21px;
  margin: 0 5px 0 0;
  ${media.tablet`
    margin-top: 10px;
    font-size: 18px;
    line-height: 22px;
  `}
`;

const nameStyles = css`
  ${roleStyles}
  font-weight: 500;
  margin: 20px 6px 0 0;
`;

const formatTitle = (title) => {
  const split = title.split("+");
  if (split.length === 2) {
    return [split[0], <span>+</span>, split[1]];
  }
  return title;
};

const TeamMember = ({ photo, jobTitle, name }) => {
  const photoUrl = addQueryParams(photo.url, {
    sat: -100,
    duotone: "000000,FFAA19",
    "duotone-alpha": 50,
  });
  return (
    <div css={teamMemberWrapper}>
      <img src={photoUrl} alt={name} css={profilePic} />
      <p css={nameStyles}>{name}</p>
      <p css={roleStyles}>{jobTitle}</p>
    </div>
  );
};

const TeamSection = ({ title, members }) => {
  const formattedTitle = formatTitle(title);
  return (
    <div css={teamWrapper}>
      <h4 css={teamHeadingStyles}>{formattedTitle}</h4>
      {members.map((person, i) => (
        <TeamMember {...person} key={i} />
      ))}
    </div>
  );
};
type Props = {
  title: string,
  repeat: {
    photo: PrismicImage,
    name: string,
    team: string,
    jobTitle: string,
  }[],
};

export const MeetTheTeam = ({ repeat, title }: Props) => {
  const teamsAndMembers = useMemo(
    () =>
      repeat.reduce((acc, member) => {
        const currentTeamMembers = acc[member.team] || [];
        return {
          ...acc,
          [member.team]: [...currentTeamMembers, member],
        };
      }, {}),
    [repeat],
  );

  return (
    <section css={sliceBackground}>
      <div css={sliceContent}>
        <h3 css={titleStyles}>{title}</h3>
        {Object.keys(teamsAndMembers).map((team, i) => (
          <TeamSection title={team} members={teamsAndMembers[team]} key={i} />
        ))}
      </div>
    </section>
  );
};
